module.exports = [{
      plugin: require('/Users/alejandromatamala/Desktop/Projects/01.Code/43.Portfolio/portfolio/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-62878348-1","head":false,"anonymize":true},
    },{
      plugin: require('/Users/alejandromatamala/Desktop/Projects/01.Code/43.Portfolio/portfolio/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/alejandromatamala/Desktop/Projects/01.Code/43.Portfolio/portfolio/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
